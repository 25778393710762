import DragIndicator from "@mui/icons-material/DragIndicator";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { EMPTY_RICH_TEXT } from "../../../../shared/frontend/constants";
import useIsComponentVisible from "../../../../shared/frontend/hooks/useIsComponentVisible";
import { ActualComponentStatus, ITipTapRichText } from "../../../../shared/types/TextItem";
import { IFUser } from "../../../../shared/types/User";
import Avatar from "../../atoms/Avatar";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import EditableTextArea from "../../atoms/EditableTextArea";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import Tooltip from "../../molecules/Tooltip";
import VariantBadge from "../../molecules/VariantBadge";
import style from "./index.module.css";

export type TextItemComponentState = "default" | "focus" | "disabled" | "dragging";

// none: not editable
// editable: allow focusing the input but don't show the save/cancel buttons
// editing: show save/cancel buttons
export type TextItemEditState = "none" | "editable" | "editing";

interface IProps {
  defaultValue?: ITipTapRichText;

  status?: ActualComponentStatus;
  variantStatuses?: ActualComponentStatus[];
  assignee?: IFUser;
  numComments?: number;
  instanceCount?: number;
  tags?: string[];
  notes?: string | null;

  canDrag?: boolean;

  state?: TextItemComponentState;
  editState?: TextItemEditState;
  expansion?: "inline" | "block";
  variant?: "default" | "published" | "unmanaged";
  level?: "default" | "empty-variant";
  showTextIcon?: boolean;
  autoFocus?: boolean;

  onClickSave?: (value: ITipTapRichText) => Promise<void> | void;
  onClickCancel?: () => Promise<void> | void;
  onTextChange?: (richText: ITipTapRichText) => void;
  onTextFocus?: React.FocusEventHandler<HTMLDivElement>;
  onTextBlur?: React.FocusEventHandler<HTMLDivElement>;

  className?: string;
  style?: React.CSSProperties;
}

export function TextItem(props: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { isVisibleRef } = useIsComponentVisible(ref, 1);

  function handleSave(richText: ITipTapRichText) {
    return props.onClickSave?.(richText);
  }

  async function handleCancel() {
    await props.onClickCancel?.();
  }

  const instanceCount = props.instanceCount ?? 0;

  useEffect(() => {
    // When the component becomes focused, scroll it into view if it's not already visible.
    if (props.state === "focus" && !isVisibleRef.current && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [props.state, isVisibleRef]);

  return (
    <div
      style={props.style}
      className={classNames(
        style.TextItemWrapper,
        {
          [style.inline]: props.expansion === "inline",
          [style[`state-${props.state}`]]: props.state,
          [style[`edit-state-${props.editState}`]]: props.editState,
          [style[`variant-${props.variant}`]]: props.variant,
          [style[`level-${props.level}`]]: props.level,
        },
        props.className
      )}
      data-testid="text-item"
      ref={ref}
    >
      <div className={style.borderWrapper} />
      {props.status && (
        <div
          className={classNames(style.status, {
            [style[`status-${props.status}`]]: props.status,
          })}
        />
      )}
      {props.canDrag && <Icon Icon={<DragIndicator />} size="xs" color="minimal" />}
      {props.showTextIcon && <TextIcon />}

      <div className={style.main}>
        <div className={style.textRow}>
          {/* TODO: Handle highlighting with rich text support. */}
          <EditableInput
            autoFocus={props.autoFocus}
            defaultValue={props.defaultValue ?? EMPTY_RICH_TEXT}
            editState={props.editState || "none"}
            onSave={handleSave}
            onCancel={handleCancel}
            onTextChange={props.onTextChange}
            onFocus={props.onTextFocus}
            onBlur={props.onTextBlur}
          />
          {!(props.editState === "editing") && (
            <div className={style.badges}>
              {props.variantStatuses && <VariantBadge statuses={props.variantStatuses} />}
              {(props.numComments ?? 0) > 0 && (
                <Badge className={style.commentsBadge} type="outline" size="sm" borderRadius="lg">
                  <Icon className={style.reflect} Icon={<ModeCommentIcon />} size="micro" color="primary" />
                  {props.numComments}
                </Badge>
              )}
              {props.assignee && (
                <Tooltip content={props.assignee.name} type="invert">
                  <Avatar userName={props.assignee.name} picture={props.assignee.picture} size="sm" />
                </Tooltip>
              )}
              {props.instanceCount !== undefined && (
                <>
                  <div className={style.badgeDivider} />
                  <Tooltip content={`${instanceCount} instances in design`} type="invert">
                    <Badge
                      className={style.instanceCount}
                      type={instanceCount ? "action-outline" : "subtle"}
                      size="sm"
                      borderRadius="md"
                    >
                      {instanceCount}
                    </Badge>
                  </Tooltip>
                </>
              )}
            </div>
          )}
        </div>

        {props.notes && (
          <Text size="micro" color="secondary">
            {props.notes}
          </Text>
        )}

        {props.tags && props.tags.length > 0 && (
          <div className={style.tags}>
            {props.tags?.map((tag, i) => (
              <Badge key={tag + i} type="outline" borderRadius="md" size="xs" className={style.tag}>
                {tag}
              </Badge>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

interface EditableInputProps {
  autoFocus?: boolean;
  editState: TextItemEditState;
  defaultValue: ITipTapRichText;
  onSave: (richText: ITipTapRichText) => void;
  onCancel: () => void;
  onTextChange?: (richText: ITipTapRichText) => void;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

function EditableInput(props: EditableInputProps) {
  const [inputValue, setInputValue] = React.useState(props.defaultValue);
  const [isSaving, setIsSaving] = React.useState(false);

  function handleChange(richText: ITipTapRichText) {
    setInputValue(richText);
    props.onTextChange?.(richText);
  }

  async function handleSave() {
    setIsSaving(true);
    await props.onSave(inputValue);
    setIsSaving(false);
  }

  async function handleCancel() {
    props.onCancel();
  }

  const isEditable = props.editState === "editable" || props.editState === "editing";
  const isEditing = props.editState === "editing";

  return (
    <div className={style.editableInput}>
      <EditableTextArea
        placeholder="Edit text"
        onTextChange={handleChange}
        onFocus={props.onFocus}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e);
        }}
        editable={isEditable}
        editing={isEditing}
        content={props.defaultValue}
        autoFocus={props.autoFocus}
      />

      {isEditing && (
        <div className={style.inlineEditButtons}>
          <Button disabled={isSaving} variant="text" onClick={handleCancel}>
            <Text color="action">Cancel</Text>
          </Button>

          <Button disabled={isSaving} onClick={handleSave}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

function TextIcon() {
  return (
    <Icon
      Icon={
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 3H13V4V6H12V4H8.5V12H10V13H6V12H7.5V4H4V6H3V4V3Z"
            fill="#A3A3A3"
          />
        </svg>
      }
      size="xs"
    />
  );
}

export default TextItem;
